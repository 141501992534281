@import "variables";

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-red {
  color: $common-red !important;
}

:root {
  --light-box-background: #f57528;
  --light-box-title-color: #fff;
  --light-box-text-color: #fff;
  --cta-text-color: #be3921;
  --cta-background: #fff;
  --page-heading-color: #f37629;
  --page-text-color: #000501;
  --spin-left-color: #fa0201;
  --footer-heading-color: #f57528;
}
